import * as React from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/layout';

const IndexPage = () => {
  return (
    <Layout>
      <Helmet>
        <title>Eric O'Hanlon - Home</title>
      </Helmet>
      <main>
        <h1>Welcome to the site!</h1>
        <p>Not much going on here right now, but thanks for stopping by!</p>
        {
          /* TODO: use Gatsby <Link> component with absolute links to link to other pages (when you have other pages). */
        }
      </main>
    </Layout>
  )
};

export default IndexPage;
